import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "titulo m-0" }
const _hoisted_2 = {
  key: 0,
  class: "legenda"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h1", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true)
  ]))
}