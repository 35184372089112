
export interface GrupoInterface {
  buscaPlaca: number | string | null;
  usaChecklist: number | string | null;
  alterarValor: string | null;
  formularioDeRecepcao: string | null;
  utilizaMO: string | null;
  oleoFracionado: string | null;
  podeDesconto: string | null;
  assinaturaDoCliente: string | null;
  vendaEstoqueNegativo: string | null;
  fornecedorBuscaPlaca?: string | number | any;
}

export interface DadosGrupo {
  codGrupo: number | null;
  nome: string | null;
  responsavel: string | null;
}

export interface PreferGrupo{
  tipoOferta: number | null;
  tipoApp: number | null;
  checklistAdicional: number | null;
}

interface ObjCompleted extends GrupoInterface, DadosGrupo, PreferGrupo{}

import ConfigApp from '@/views/grupos/components/ConfigApp.vue'
import { defineComponent, onBeforeMount, onBeforeUnmount, onMounted, ref, Ref } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";
import Api from "@/services/Api";
import { useRoute, useRouter } from 'vue-router';
import ConfigGrupo from './components/ConfigGrupo.vue';
import ConfigPreferGrupo from './components/ConfigPreferGrupo.vue';
import Title from '@/components/Title.vue';
import Button from '@/components/Button.vue';
import ConfigMarcasParceiras from './components/ConfigMarcasParceiras.vue';
import { Marcas } from '../store/components/MarcaPadrao.vue';

export default defineComponent({
  name: "EditarGrupo",
  components: {ConfigApp, ConfigGrupo, ConfigPreferGrupo, Title, Button, ConfigMarcasParceiras,},
  props: { princTitle: String },
  setup() {
    const { showTimeAlert } = useAlert()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()
    const loadingSalvar: Ref<boolean> = ref(false)
    const grupo: Ref<GrupoInterface> = ref({
        buscaPlaca:  null,
        usaChecklist:  null,
        alterarValor: null,
        formularioDeRecepcao: null,
        utilizaMO: null,
        oleoFracionado: null,
        podeDesconto: null,
        assinaturaDoCliente: null,
        vendaEstoqueNegativo: null,
        fornecedorBuscaPlaca: null,
      })
    const dadosGrupo: Ref<DadosGrupo> = ref({
      codGrupo: null,
      nome: null,
      responsavel: null
    })
    const preferGrupo: Ref<PreferGrupo> = ref({
      tipoOferta: null,
      tipoApp: null,
      checklistAdicional: null
    })
    const grupoCompleto: Ref<ObjCompleted> = ref({
        buscaPlaca:  null,
        usaChecklist:  null,
        alterarValor: null,
        formularioDeRecepcao: null,
        utilizaMO: null,
        oleoFracionado: null,
        podeDesconto: null,
        assinaturaDoCliente: null,
        vendaEstoqueNegativo: null,
        codGrupo: null,
        nome: null,
        responsavel: null,
        tipoOferta: null,
        tipoApp: null,
        checklistAdicional: null,
        fornecedorBuscaPlaca: null,
    })
    const marcasParceirasApi: Ref<Marcas> = ref({
      oleos: [],
      filtroOleo: [],
      filtroCombustivel: [],
      filtroCabine: [],
      filtroAr: [],
      pneus: [],
    });
    const marcasParceiras: Ref<Marcas> = ref({
      oleos: [],
      filtroOleo: [],
      filtroCombustivel: [],
      filtroCabine: [],
      filtroAr: [],
      pneus: [],
    });
    const loadSalva: Ref<boolean> = ref(false);
    const loadExclui: Ref<boolean> = ref(false);

    const tentouSalvar: Ref<boolean> = ref(false);

    async function getGrupo(id: number | null) {
      if(id === null) return;
      try {
        const {data} = await Api.get('getGrupoById', {codGrupo: id})
        grupo.value = data.grupo
        const {parceiros} = data
        dadosGrupo.value = {nome: data.grupo.nome, responsavel: data.grupo.responsavel, codGrupo: data.grupo.codGrupo}
        preferGrupo.value = {tipoOferta: data.grupo.tipoOferta, tipoApp: data.grupo.tipoApp, checklistAdicional: data.grupo.checklistAdicional}
        marcasParceirasApi.value = parceiros
      } catch (error) {
        console.log(error)
      }
    }

    function receiveAttComponents(obj: object){
      grupoCompleto.value = {...grupoCompleto.value, ...obj}
      console.log('att', grupoCompleto.value)
    }

    function receiveAttParceiros(obj: object){
      let { filtroAr, filtroCabine, filtroCombustivel, filtroOleo, oleos, pneus} = obj as any;
      marcasParceiras.value = {filtroAr, filtroCabine, filtroCombustivel, filtroOleo, oleos, pneus}
    }

    async function salvaGrupo() {
      try {
        tentouSalvar.value = true;
        let newObj: any;

        if (grupoCompleto.value.codGrupo === null) {
          let { codGrupo, ...meuObj } = grupoCompleto.value;

          newObj = meuObj;
        }

        // if (!grupo.value.nome || !grupo.value.responsavel) return showTimeAlert("campos obrigatórios estão vazios", "error");

        loadingSalvar.value = true;

        const { data } = await Api.post("salvaGrupo", { grupo: newObj ? newObj : grupoCompleto.value });
        if(!data.grupo){
        loadingSalvar.value = false;
          showTimeAlert("Não foi possivel salvar, verifique os dados e tente novamente.", "error");
          return
        }
        const res = await Api.post('salvaParceirosGrupo', {
          codGrupo: data.grupo.codGrupo, 
          parceiros:{ 
            filtroAr: marcasParceiras.value.filtroAr, 
            filtroOleo: marcasParceiras.value.filtroOleo, 
            filtroCombustivel: marcasParceiras.value.filtroCombustivel, 
            oleo: marcasParceiras.value.oleos,
            pneu: marcasParceiras.value.pneus,
            filtroAc: marcasParceiras.value.filtroCabine 
          }})
        loadingSalvar.value = false;

        if (!data.grupo) return showTimeAlert(data?.message, "error");

        // grupo.value = data.grupo;

        showTimeAlert("grupo salvo com sucesso!");

        // atualizaGrupo(data.grupo);

        // fechaModal();
      } catch (error) {
        loadSalva.value = false;
      }
    }

    async function redirectToGroups(){
      await router.push({name:'grupos'})
    };

    onBeforeMount(() =>getGrupo(route.query.idGrupo === null ? null : Number(route.query.idGrupo)))

    return {grupo, dadosGrupo, preferGrupo, marcasParceirasApi, route, router, loadingSalvar, redirectToGroups, receiveAttParceiros, receiveAttComponents, salvaGrupo};
  },
});
