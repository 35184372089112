
interface list {
  codMarcaProduto: number;
  nome: string;
  ativo: string;
  id: number;
  match: string;
}
import { Ref, onMounted, ref, watch } from "vue";
import Button from "@/components/Button.vue";
import Title from '@/components/Title.vue';
export default {
  name: "ConfigMarcasDragAndDrop",
  components: {
    Button,
    Title,
  },
  emits: ["fecharModal", "atualizarNumeros", "attLista"],
  props: { produtos: Array },
  setup(props, { emit }) {
    const onOffToggle = ref(false);
    const selecteAll = ref(false);
    const targetIndex = ref(null);
    const listaProdutos: Ref<list[]> = ref(props.produtos as any);
    function changeActive(id: number) {
      try {
        listaProdutos.value &&
          listaProdutos.value.forEach(item => {
            if (item.codMarcaProduto === id) {
              if (item.ativo === "sim") {
                item.ativo = "nao";
              } else {
                item.ativo = "sim";
              }
            }
          });
      } catch (error) {
        console.error(error);
      }
    }
    const newList: Ref<any> = ref(null);
    const draggingItem: Ref<any> = ref(null);
    function dragStart(index) {
      draggingItem.value = index;
    }
    function dragEnd() {
      draggingItem.value = null;
    }
    function dragOver(index, event) {
      event.preventDefault();
      targetIndex.value = index;
    }
    function drop(index) {
      if (draggingItem.value === null || targetIndex.value === null) return;
      if (draggingItem.value !== targetIndex.value) {
        const temp = listaProdutos.value[draggingItem.value];
        listaProdutos.value[draggingItem.value] = listaProdutos.value[targetIndex.value];
        listaProdutos.value[targetIndex.value] = temp;
      }
      draggingItem.value = null;
      targetIndex.value = null;
    }
    function enviarOrdemAtualizadaPai() {
      emit("attLista", listaProdutos.value, listaProdutos.value[0].match);
    }
    
    function atualizaSelectGeral(){
      //verifica se todos os filtros estão marcados e atualiza o select geral para true
      //se todos estiverem desmarcados atualiza para false
      selecteAll.value = listaProdutos.value.every((item) => {
        return item.ativo === 'sim'
      })
    }

    function selecionarTodosToggle() {
      try {
        listaProdutos.value &&
          listaProdutos.value.forEach(item => {
            item.ativo = !selecteAll.value ? "nao" : "sim";
          });
      } catch (error) {
        console.error(error);
      }
    }

    watch(
      () => props.produtos,
      () => {
        listaProdutos.value = props.produtos as any
        atualizaSelectGeral()
      }
    );

    watch(
      () => listaProdutos.value, 
      () => enviarOrdemAtualizadaPai(), 
      { deep: true }
    )

    onMounted(()=> {atualizaSelectGeral()})

    return {
      dragStart,
      dragEnd,
      dragOver,
      drop,
      onOffToggle,
      selecteAll,
      newList,
      listaProdutos,
      changeActive,
      enviarOrdemAtualizadaPai,
      selecionarTodosToggle
    };
  },
};
