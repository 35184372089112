
import { defineComponent, onBeforeMount, onBeforeUpdate, onMounted, ref, Ref, watch } from "vue";
import type { PropType, pushScopeId, toValue } from "vue";
import { GrupoInterface } from "../EditarGrupo.vue";

export default defineComponent({
  name: "ConfigApp",
  props: {grupoSelecionado: Object as PropType<GrupoInterface>,},
  emits: ['receiveAttComponents'],
  setup(props, {emit}) {
    const grupoProp: Ref<GrupoInterface> = ref({
      buscaPlaca:  null,
      usaChecklist:  null,
      alterarValor: null,
      formularioDeRecepcao: null,
      utilizaMO: null,
      oleoFracionado: null,
      podeDesconto: null,
      assinaturaDoCliente: null,
      vendaEstoqueNegativo: null,
      fornecedorBuscaPlaca: null,
    }) 

    const options: Ref<any> = ref([
      {name: 'denatran', id: 1},
      {name: 'suiv', id: 2}
    ]);

    watch(
      () => grupoProp.value, 
      () => {emit('receiveAttComponents', grupoProp.value)},
      { deep: true }
    )

    watch(() => props.grupoSelecionado, () => grupoProp.value = props.grupoSelecionado as any)

    return {grupoProp, options};
  },
});
