
import { defineComponent, onBeforeUnmount, onMounted, ref, Ref, watch } from "vue";
import { DadosGrupo, PreferGrupo } from "../EditarGrupo.vue";
import type { PropType } from "vue";
import Api from "@/services/Api";
import { Marcas } from "@/views/store/components/MarcaPadrao.vue";
import useEmitter from "@/composables/Emmiter";
import Button from "@/components/Button.vue";
import ConfigMarcasDragAndDropVue from "./ConfigMarcasDragAndDrop.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { Button, ConfigMarcasDragAndDropVue },
  name: "ConfigMarcasParceiras",
  props: { MarcasParceiras: Object as PropType<PreferGrupo>, marcasParceirasApi: Object as PropType<Marcas> },
  emits: ["receiveAttParceiros"],
  setup(props, { emit }) {
    const dialogWidth: Ref<string> = ref("80%");
    const route = useRoute();
    const modalTitle: Ref<string> = ref("");
    const propDragDrop: Ref<any> = ref([]);
    const marcasParceiras: Ref<Marcas> = ref({
      oleos: [],
      filtroAr: [],
      filtroCabine: [],
      filtroCombustivel: [],
      filtroOleo: [],
      pneus: [],
    });

    const marcasParceirasTotal: Ref<Marcas> = ref({
      oleos: [],
      filtroAr: [],
      filtroCabine: [],
      filtroCombustivel: [],
      filtroOleo: [],
      pneus: [],
    });

    function sendArrayCorrectly(name: string) {
      propDragDrop.value = [];
      try {
        const { id } = marcasParceiras.value;
        const match = Object.keys(marcasParceiras.value).find(item => item === name);
        propDragDrop.value = marcasParceiras.value[match as any].map((item: any) => ({ ...item, id, match }));
      } catch (error) {
        console.log(error);
      }
    }

    function attSelecionados(arr, str) {
      console.log('ola caralho')
      const keys = Object.keys(marcasParceirasTotal.value);
      const myKeyIndex = keys.indexOf(str);
      if (myKeyIndex !== -1) {
        const newKeyObj = { [str]: arr.filter(item => item.ativo === "sim") };
        const newMarcasParceirasTotal = { ...marcasParceirasTotal.value, ...newKeyObj };
        marcasParceirasTotal.value = newMarcasParceirasTotal;
      }
    }

    function atualizarListaReordenada(arr: any, str: any) {
      try {
        
        marcasParceiras.value[str] = arr;
        marcasParceirasTotalCount.value[str] = arr.filter(item => item.ativo === "sim").length as any;
        console.log('entreiiii',marcasParceiras.value[str])
        emit('receiveAttParceiros', marcasParceiras.value)
      } catch (error) {
        console.log(error);
      }
    }

    const setModalTitle = (evt: any) => {
      let title = evt.target.dataset.title;

      if (typeof title === "string" && title.trim() !== "") {
        modalTitle.value = title.trim();
      }
    };

    async function getParceiros() {
      const res = await Api.get(`getAllParceiros`);
      const { oleo, filtroAr, filtroAc, filtroCombustivel, filtroOleo, pneu } = res;
      marcasParceiras.value = {
        oleos: oleo,
        filtroAr: filtroAr,
        filtroCabine: filtroAc,
        filtroCombustivel: filtroCombustivel,
        filtroOleo: filtroOleo,
        pneus: pneu,
      };
      return;
    }

    onMounted(() => {if(route.query.idGrupo === null) return getParceiros()});


      // if(route.query.idGrupo !== null) {
      //   //return marcasParceiras.value = {...props.marcasParceirasApi as any}
      //   const { oleos, filtroAr, filtroCabine, filtroCombustivel, filtroOleo, pneus } = props.marcasParceirasApi as any;
      //     marcasParceiras.value = {
      //     oleos: oleos,
      //     filtroAr: filtroAr,
      //     filtroCabine: filtroCabine,
      //     filtroCombustivel: filtroCombustivel,
      //     filtroOleo: filtroOleo,
      //     pneus: pneus,
      //   };
      //   console.log('marcas', marcasParceiras.value)
      //   return;
      // }
    const marcasParceirasTotalCount: Ref<any> = ref({
        oleos: 0,
        filtroAr: 0,
        filtroCabine: 0,
        filtroCombustivel: 0,
        filtroOleo: 0,
        pneus: 0,
      });

    watch(
      () => props.marcasParceirasApi,
      () => (
        console.log("oi", props.marcasParceirasApi),
        marcasParceirasTotalCount.value = {
          oleos: props.marcasParceirasApi?.oleos.filter(item => item.ativo === "sim").length as any,
          filtroAr: props.marcasParceirasApi?.filtroAr.filter(item => item.ativo === "sim").length as any,
          filtroCabine: props.marcasParceirasApi?.filtroCabine.filter(item => item.ativo === "sim").length as any,
          filtroCombustivel: props.marcasParceirasApi?.filtroCombustivel.filter(item => item.ativo === "sim").length as any,
          filtroOleo: props.marcasParceirasApi?.filtroOleo.filter(item => item.ativo === "sim").length as any,
          pneus: props.marcasParceirasApi?.pneus.filter(item => item.ativo === "sim").length as any,
        }
      )
    );
    watch(
      () => props.marcasParceirasApi,
      () => (marcasParceiras.value = props.marcasParceirasApi as any)
    );

    return {
      modalTitle,
      setModalTitle,
      marcasParceirasTotal,
      marcasParceiras,
      attSelecionados,
      sendArrayCorrectly,
      propDragDrop,
      atualizarListaReordenada,
      dialogWidth,
      marcasParceirasTotalCount
    };
  },
});
