
import { defineComponent, onMounted, Ref, ref } from "vue"
 
 
export default defineComponent({
  name: "Button",
    props: {
      variant: String,
      text: String,
    },
    setup(props){

      const variantValue: Ref<string> = ref('')

      onMounted(() => {
        if(!props.variant){
           variantValue.value = "btn-light-danger bg-white"
        }
        if(props.variant === 'primary'){
           variantValue.value = "btn-light-danger"
        }
        if(props.variant === 'secondary'){
           variantValue.value = "btn-danger"
        }

      })
    
      return{
        variantValue,
      }
    }
 })

