
import { defineComponent, onMounted, ref, Ref, watch } from "vue";
import { DadosGrupo, PreferGrupo } from "../EditarGrupo.vue";
import type { PropType } from "vue";
import Api from "@/services/Api";

export default defineComponent({
  name: "ConfigPreferGrupo",
  props: { preferGrupo: Object as PropType<PreferGrupo>, },
  emits: ['receiveAttComponents'],
  setup(props, {emit}) {
    const arrayTipoOferta: Ref<any[]> = ref([]);
    const arrayLayoutApp: Ref<any[]> = ref([]);
    const arrayChecklistAdicional: Ref<any[]> = ref([]);
    const preferGrupoProp: Ref<PreferGrupo> = ref({
      tipoOferta: null,
      tipoApp: null,
      checklistAdicional: null
    })

    onMounted(async()=>{ 
      try{        
        arrayTipoOferta.value = (await Api.get('getTipoOferta') as any).data.tipos || [];
        arrayLayoutApp.value = (await Api.get('getLayoutApp') as any).data.layouts || [];
        arrayChecklistAdicional.value = (await Api.get('getChecklist', { tipo: 'adicional'}) as any).data.checklists || [];

        arrayTipoOferta.value.unshift({id: 0, nome: 'Sem tipo oferta'})
        arrayLayoutApp.value.unshift({codBandeira: 0, descricao: 'Sem layout'})
        arrayChecklistAdicional.value.unshift({checklist: 0, nome: 'Sem checklist'})
      } catch(error) { console.error(error) }
    });

    watch(
      () => preferGrupoProp.value, 
      () => {emit('receiveAttComponents', preferGrupoProp.value)}, 
      { deep: true }
    )


    watch(
      () => props.preferGrupo,
      () => (preferGrupoProp.value = props.preferGrupo as any)
    );

    return { preferGrupoProp, arrayTipoOferta, arrayLayoutApp, arrayChecklistAdicional };
  },
});
