
import { defineComponent, ref, Ref, watch } from "vue"
import { DadosGrupo } from "../EditarGrupo.vue";
import type { PropType } from "vue";

export default defineComponent({
    name: 'ConfigGrupo',
    props: { dadosGrupo: Object as PropType<DadosGrupo>, },
    emits: ['receiveAttComponents'],
    setup(props, {emit}) {
        const dadosProp: Ref<DadosGrupo> = ref({
            codGrupo: null,
            nome: null,
            responsavel: null
        })

        watch(
            () => dadosProp.value, 
            () => {emit('receiveAttComponents', dadosProp.value)}, 
            { deep: true }
        )



        watch(() => props.dadosGrupo, () => dadosProp.value = props.dadosGrupo as any)



        return {dadosProp}
    },
})
