
import { defineComponent } from "vue"

export default defineComponent({
    name: "Title",
    props: {
        description: String,    
    },
    setup(props){
        return{}
    }
})
